import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import Projects from "../components/Projects"
import GetUpdates from "../components/GetUpdates"

const ProjectTemplate = ({ data }) => {
  const {
    body,
    links,
    methods,
    partners,
    citationDate,
    slug,
    status,
    subtitle,
    summary,
    timeline,
    title
  } = data.contentfulProject
  const url = `https://thepolicylab.brown.edu/projects/${slug}`

  return (
    <>
      <Seo title={title} bodyClass="layout__project presentation" description={summary.childMarkdownRemark.excerpt} article={true} />
      <Layout>
        <main id="content" className="layout__main s__white project">
          <article className="row flex-row-reverse u__container u__vspacing project__content">
            <header className="project__content__header u__spacing">
              <div className="row u__container justify-content-center text-center">
                <div className="col-12 project__content__landing-link-wrapper">
                  <hr />
                  <Link to="/projects" className="project__content__landing-link">Projects</Link>
                </div>
                <div className="col-9 project__content__title-wrapper">
                  <h1 className="project__content__title display-5 my-4">{title}</h1>
                  {subtitle &&
                    <p className="project__content__subtitle">{subtitle.internal.content}</p>
                  }
                </div>
              </div>
            </header>
            <div className="col-lg-9">
              <div className="project__content__body">
                <div className="s__blue project__content__body__summary mb-4 mb-lg-5">
                  <h2 className="lead">Project Summary</h2>
                  <p className="summary">{summary.childMarkdownRemark.internal.content}</p>
                </div>
                <div dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}></div>
                <div className="citation">
                  <hr />
                  <p>
                    {`How to cite this Project: The Policy Lab. (${citationDate}). ${title}.`} <em>The Policy Lab</em>. <a href={url}>{url}</a>
                  </p>
                </div>
              </div>
            </div>
            <aside className="col-lg-3">
              <div className="s__light project__content__aside">
                {partners &&
                  <ul className="project__content__aside__list" aria-label="Partners">
                    {partners.map((partner) => (
                      <li key={partner.contentful_id} className="project__content__aside__list-item">
                        <a href={partner.url} target="_blank" rel="nofollow noreferrer">{partner.name}</a>
                      </li>
                    ))}
                  </ul>
                }
                {timeline &&
                  <ul className="project__content__aside__list" aria-label="Timeline">
                    <li className="project__content__aside__list-item">
                      {timeline}
                    </li>
                  </ul>
                }
                {status &&
                  <ul className="project__content__aside__list" aria-label="Status">
                    <li className="project__content__aside__list-item">
                      {status}
                    </li>
                  </ul>
                }
                {methods &&
                  <ul className="project__content__aside__list" aria-label="Methods">
                    {methods.map((method) => (
                      <li className="project__content__aside__list-item" key={method.contentful_id}>
                        {method.title}
                      </li>
                    ))}
                  </ul>
                }
                {links &&
                  <ul className="project__content__aside__list" aria-label="Links">
                    {links.map((link) => (
                      <li className="project__content__aside__list-item" key={link.contentful_id}>
                        <a href={link.hostedMedia ? link.hostedMedia.file.url : link.link} target="_blank" rel="nofollow noreferrer">{link.displayText}</a>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            </aside>
          </article>
        </main>
        <Projects slug={slug} header="More Projects" />
        <GetUpdates sectionClass="s__light" />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ProjectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      links {
        contentful_id
        displayText
        link
        hostedMedia {
          contentful_id
          file {
            url
          }
        }
      }
      methods {
        contentful_id
        title
      }
      partners {
        contentful_id
        name
        url
      }
      citationDate: publishDate(formatString: "YYYY, MMMM DD")
      slug
      status
      subtitle {
        ... on Node {
          internal {
            content
          }
        }
      }
      summary {
        childMarkdownRemark {
          excerpt
          internal {
            content
          }
        }
      }
      timeline
      title
    }
  }
`

export default ProjectTemplate
